<template>
  <v-dialog v-model="showFormDialog" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.client_id"
                  :items="forSelect(data.clients)"
                  item-text="text"
                  item-value="id"
                  prepend-icon="person"
                  :label="$t('projects.fields.client')"
                  :rules="rules.client_id"
                >
                </v-select>
                <v-text-field
                  v-model="form.name"
                  prepend-icon="store"
                  :label="$t('projects.fields.name')"
                  :rules="rules.name"
                ></v-text-field>

                <v-text-field
                  v-model="form.image_url"
                  :label="$t('projects.fields.image_url')"
                  name="image_url"
                  prepend-icon="image"
                  type="text"
                  :rules="rules.image_url"
                  @input="resetInputError('image_url')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-textarea
                  v-model="urls"
                  :label="
                    $t('projects.fields.hosts') +
                      ' (' +
                      $t('projects.one per line') +
                      ')'
                  "
                >
                </v-textarea>

                <v-select
                  :items="forSelect(form.quizzes)"
                  v-model="form.quiz_id"
                  item-text="text"
                  item-value="id"
                  :label="$t('projects.default quiz')"
                  prepend-icon="forum"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <h4>{{ $t("projects.email settings") }}</h4>
                <v-text-field
                  v-model="form.email_link"
                  :label="$t('projects.fields.email_link')"
                  name="email_link"
                  prepend-icon="link"
                  type="text"
                  :rules="rules.email_link"
                  @input="resetInputError('email_link')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="form.smtp_on"
                  :label="$t('projects.fields.smtp_on')"
                ></v-checkbox>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.hostname"
                  :label="$t('settings.smtp.hostname')"
                  type="text"
                  :rules="
                    defaultValidation(
                      'smtp.hostname',
                      $t('settings.smtp.hostname')
                    )
                  "
                  @input="resetInputError('smtp.hostname')"
                ></v-text-field>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.port"
                  :label="$t('settings.smtp.port')"
                  type="text"
                  :rules="
                    defaultValidation('smtp.port', $t('settings.smtp.port'))
                  "
                  @input="resetInputError('smtp.port')"
                ></v-text-field>

                <v-select
                  v-show="form.smtp_on"
                  v-model="form.smtp.encryption"
                  :items="smtpEnc"
                  :label="$t('settings.smtp.encryption')"
                >
                </v-select>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.username"
                  :label="$t('settings.smtp.username')"
                  type="text"
                  :rules="
                    defaultValidation(
                      'smtp.username',
                      $t('settings.smtp.username')
                    )
                  "
                  @input="resetInputError('smtp.username')"
                ></v-text-field>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.password"
                  :label="$t('settings.smtp.password')"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :rules="
                    defaultValidation(
                      'smtp.password',
                      $t('settings.smtp.password')
                    )
                  "
                  @input="resetInputError('smtp.password')"
                  @click:append="show1 = !show1"
                ></v-text-field>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.email"
                  :label="$t('settings.smtp.email')"
                  type="text"
                  :rules="
                    defaultValidation('smtp.email', $t('settings.smtp.email'))
                  "
                  @input="resetInputError('smtp.email')"
                ></v-text-field>

                <v-text-field
                  v-show="form.smtp_on"
                  v-model="form.smtp.from"
                  :label="$t('settings.smtp.from')"
                  type="text"
                  :rules="
                    defaultValidation('smtp.from', $t('settings.smtp.from'))
                  "
                  @input="resetInputError('smtp.from')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="form.imap_on"
                  :label="$t('projects.fields.imap_on')"
                ></v-checkbox>

                <v-text-field
                  v-show="form.imap_on"
                  v-model="form.imap.hostname"
                  :label="$t('settings.imap.hostname')"
                  type="text"
                  :rules="
                    defaultValidation(
                      'imap.hostname',
                      $t('settings.imap.hostname')
                    )
                  "
                  @input="resetInputError('imap.hostname')"
                ></v-text-field>

                <v-text-field
                  v-show="form.imap_on"
                  v-model="form.imap.port"
                  :label="$t('settings.imap.port')"
                  type="text"
                  :rules="
                    defaultValidation('imap.port', $t('settings.imap.port'))
                  "
                  @input="resetInputError('imap.port')"
                ></v-text-field>

                <v-select
                  v-show="form.imap_on"
                  v-model="form.imap.encryption"
                  :items="smtpEnc"
                  :label="$t('settings.imap.encryption')"
                >
                </v-select>

                <v-text-field
                  v-show="form.imap_on"
                  v-model="form.imap.username"
                  :label="$t('settings.imap.username')"
                  type="text"
                  :rules="
                    defaultValidation(
                      'imap.username',
                      $t('settings.imap.username')
                    )
                  "
                  @input="resetInputError('imap.username')"
                ></v-text-field>

                <v-text-field
                  v-show="form.imap_on"
                  v-model="form.imap.password"
                  :label="$t('settings.imap.password')"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :rules="
                    defaultValidation(
                      'imap.password',
                      $t('settings.imap.password')
                    )
                  "
                  @input="resetInputError('imap.password')"
                  @click:append="show2 = !show2"
                ></v-text-field>

                <v-text-field
                  v-show="form.imap_on"
                  v-model="form.imap.inbox"
                  :label="$t('settings.imap.inbox')"
                  type="text"
                  :rules="
                    defaultValidation('imap.inbox', $t('settings.imap.inbox'))
                  "
                  @input="resetInputError('imap.inbox')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("projects.saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/projects/",
      reloadBeforeEdit: true,
      defaultItem: {
        name: "",
        hosts: [],
        quizzes: [],
        quiz_id: null,
        smtp_on: false,
        smtp: {
          encryption: "TLS",
          port: "587",
        },
        imap_on: false,
        imap: {
          inbox: "INBOX",
          encryption: "TLS",
          port: "993",
        },
      },
      smtpEnc: ["-", "SSL", "TLS"],
      show1: false,
      show2: false,
      rules: {
        client_id: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("projects.fields.client"),
            }),
        ],
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("projects.fields.name"),
            }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("projects.fields.name"),
            }),
          ...this.defaultValidation("name", this.$t("projects.fields.name")),
        ],
        image_url: [
          (v) => {
            return (
              !v || validate.url.test(v) || this.$t("projects.invalid url")
            );
          },
          ...this.defaultValidation(
            "image_url",
            this.$t("projects.fields.image_url")
          ),
        ],
        email_link: [
          (v) => {
            return (
              !v ||
              validate.url.test(v) ||
              this.$t("projects.invalid email link")
            );
          },
          ...this.defaultValidation(
            "email_link",
            this.$t("projects.fields.email_link")
          ),
        ],
      },
    };
  },
  created() {
    this.form = this.defaultItem;
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("projects.add")
        : this.$t("projects.edit");
    },
    urls: {
      get() {
        if (
          this.form.hosts &&
          this.form.hosts.length &&
          this.form.hosts.reduce
        ) {
          return this.form.hosts.reduce((acc, v) => {
            return acc + "\n" + v;
          });
        }
        return "";
      },
      set(data) {
        this.form.hosts = data.split("\n");
      },
    },
  },
  methods: {},
};
</script>
