<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-home" :title="$t('projects.page title')">
      <projects-form
        ref="editForm"
        :data="meta"
        @itemSaved="onSaved"
      ></projects-form>

      <members-form
        ref="editMembersForm"
        :data="meta"
        @itemSaved="onSaved"
      ></members-form>

      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('projects.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          {{ $t("projects.confirm delete", item) }}</template
        >

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">{{
            $t("projects.deleted", item)
          }}</v-alert>
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        group-by="client_id"
        show-group-by
        multi-sort
        :footer-props="{
          'items-per-page-options': [20, 50],
        }"
      >
        <template v-slot:[`group.header`]="{ items, isOpen, toggle, remove }">
          <th colspan="5">
            <v-icon @click="toggle"
              >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
            </v-icon>
            {{ items[0].client_name }}
          </th>
          <th>
            <v-icon @click="remove">{{ "mdi-close" }} </v-icon>
          </th>
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:[`item.client_id`]="{ item }">
          {{ item.client_name }}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <b>{{ item.name }}</b
          ><br />
          <img
            v-if="item.image_url"
            :src="item.image_url"
            class="project_image"
          />
        </template>

        <template v-slot:[`item.hosts`]="{ item }">
          <a
            v-for="(h, index) in item.hosts"
            :key="'h-' + index"
            class="project_url"
            target="_blank"
            :href="'http://' + h"
            >{{ h }}</a
          >
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-home-plus</v-icon>
              {{ $t("projects.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="onEditMembers(item)"
            >mdi-account-plus</v-icon
          >
          <v-icon v-if="item.id" small @click="onDelete(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import ProjectsForm from "./ProjectsForm";
import MembersForm from "./MembersForm";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    ProjectsForm,
    MembersForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: "/projects/",
      headers: [
        { text: "ID", align: "start", value: "id", groupable: false },
        { text: this.$t("projects.fields.client"), value: "client_id" },
        {
          text: this.$t("projects.fields.name"),
          value: "name",
          groupable: false,
        },
        {
          text: this.$t("projects.fields.email_link"),
          value: "email_link",
          groupable: false,
        },
        {
          text: this.$t("projects.fields.hosts"),
          value: "hosts",
          groupable: false,
        },
        {
          text: this.$t("projects.fields.members"),
          value: "members_count",
          groupable: false,
        },
        {
          text: this.$t("projects.actions"),
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  created() {
    this.options.sortBy = ["client_id"];
    this.loadMeta("create/", this.onMetaLoaded);
  },
  methods: {
    onEditMembers(item) {
      this.$refs.editMembersForm.editMembers(item, this.items.indexOf(item));
    },
    onMetaLoaded(data) {
      let c = parseInt(Object.keys(data.clients)[0]);
      this.$refs.editForm.defaultItem.client_id = c;
    },
  },
};
</script>

<style lang="scss" scoped>
.project_image {
  max-height: 30px;
  max-width: 150px;
}

.project_url {
  &::after {
    content: "\A";
    white-space: pre;
  }
}
</style>
