var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{on:{"click:outside":_vm.closeForm},model:{value:(_vm.showFormDialog),callback:function ($$v) {_vm.showFormDialog=$$v},expression:"showFormDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveItem($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.forSelect(_vm.data.clients),"item-text":"text","item-value":"id","prepend-icon":"person","label":_vm.$t('projects.fields.client'),"rules":_vm.rules.client_id},model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}}),_c('v-text-field',{attrs:{"prepend-icon":"store","label":_vm.$t('projects.fields.name'),"rules":_vm.rules.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"label":_vm.$t('projects.fields.image_url'),"name":"image_url","prepend-icon":"image","type":"text","rules":_vm.rules.image_url},on:{"input":function($event){return _vm.resetInputError('image_url')}},model:{value:(_vm.form.image_url),callback:function ($$v) {_vm.$set(_vm.form, "image_url", $$v)},expression:"form.image_url"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"label":_vm.$t('projects.fields.hosts') +
                    ' (' +
                    _vm.$t('projects.one per line') +
                    ')'},model:{value:(_vm.urls),callback:function ($$v) {_vm.urls=$$v},expression:"urls"}}),_c('v-select',{attrs:{"items":_vm.forSelect(_vm.form.quizzes),"item-text":"text","item-value":"id","label":_vm.$t('projects.default quiz'),"prepend-icon":"forum"},model:{value:(_vm.form.quiz_id),callback:function ($$v) {_vm.$set(_vm.form, "quiz_id", $$v)},expression:"form.quiz_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("projects.email settings")))]),_c('v-text-field',{attrs:{"label":_vm.$t('projects.fields.email_link'),"name":"email_link","prepend-icon":"link","type":"text","rules":_vm.rules.email_link},on:{"input":function($event){return _vm.resetInputError('email_link')}},model:{value:(_vm.form.email_link),callback:function ($$v) {_vm.$set(_vm.form, "email_link", $$v)},expression:"form.email_link"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('projects.fields.smtp_on')},model:{value:(_vm.form.smtp_on),callback:function ($$v) {_vm.$set(_vm.form, "smtp_on", $$v)},expression:"form.smtp_on"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.hostname'),"type":"text","rules":_vm.defaultValidation(
                    'smtp.hostname',
                    _vm.$t('settings.smtp.hostname')
                  )},on:{"input":function($event){return _vm.resetInputError('smtp.hostname')}},model:{value:(_vm.form.smtp.hostname),callback:function ($$v) {_vm.$set(_vm.form.smtp, "hostname", $$v)},expression:"form.smtp.hostname"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.port'),"type":"text","rules":_vm.defaultValidation('smtp.port', _vm.$t('settings.smtp.port'))},on:{"input":function($event){return _vm.resetInputError('smtp.port')}},model:{value:(_vm.form.smtp.port),callback:function ($$v) {_vm.$set(_vm.form.smtp, "port", $$v)},expression:"form.smtp.port"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"items":_vm.smtpEnc,"label":_vm.$t('settings.smtp.encryption')},model:{value:(_vm.form.smtp.encryption),callback:function ($$v) {_vm.$set(_vm.form.smtp, "encryption", $$v)},expression:"form.smtp.encryption"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.username'),"type":"text","rules":_vm.defaultValidation(
                    'smtp.username',
                    _vm.$t('settings.smtp.username')
                  )},on:{"input":function($event){return _vm.resetInputError('smtp.username')}},model:{value:(_vm.form.smtp.username),callback:function ($$v) {_vm.$set(_vm.form.smtp, "username", $$v)},expression:"form.smtp.username"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.password'),"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":_vm.defaultValidation(
                    'smtp.password',
                    _vm.$t('settings.smtp.password')
                  )},on:{"input":function($event){return _vm.resetInputError('smtp.password')},"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.form.smtp.password),callback:function ($$v) {_vm.$set(_vm.form.smtp, "password", $$v)},expression:"form.smtp.password"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.email'),"type":"text","rules":_vm.defaultValidation('smtp.email', _vm.$t('settings.smtp.email'))},on:{"input":function($event){return _vm.resetInputError('smtp.email')}},model:{value:(_vm.form.smtp.email),callback:function ($$v) {_vm.$set(_vm.form.smtp, "email", $$v)},expression:"form.smtp.email"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.smtp_on),expression:"form.smtp_on"}],attrs:{"label":_vm.$t('settings.smtp.from'),"type":"text","rules":_vm.defaultValidation('smtp.from', _vm.$t('settings.smtp.from'))},on:{"input":function($event){return _vm.resetInputError('smtp.from')}},model:{value:(_vm.form.smtp.from),callback:function ($$v) {_vm.$set(_vm.form.smtp, "from", $$v)},expression:"form.smtp.from"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('projects.fields.imap_on')},model:{value:(_vm.form.imap_on),callback:function ($$v) {_vm.$set(_vm.form, "imap_on", $$v)},expression:"form.imap_on"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"label":_vm.$t('settings.imap.hostname'),"type":"text","rules":_vm.defaultValidation(
                    'imap.hostname',
                    _vm.$t('settings.imap.hostname')
                  )},on:{"input":function($event){return _vm.resetInputError('imap.hostname')}},model:{value:(_vm.form.imap.hostname),callback:function ($$v) {_vm.$set(_vm.form.imap, "hostname", $$v)},expression:"form.imap.hostname"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"label":_vm.$t('settings.imap.port'),"type":"text","rules":_vm.defaultValidation('imap.port', _vm.$t('settings.imap.port'))},on:{"input":function($event){return _vm.resetInputError('imap.port')}},model:{value:(_vm.form.imap.port),callback:function ($$v) {_vm.$set(_vm.form.imap, "port", $$v)},expression:"form.imap.port"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"items":_vm.smtpEnc,"label":_vm.$t('settings.imap.encryption')},model:{value:(_vm.form.imap.encryption),callback:function ($$v) {_vm.$set(_vm.form.imap, "encryption", $$v)},expression:"form.imap.encryption"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"label":_vm.$t('settings.imap.username'),"type":"text","rules":_vm.defaultValidation(
                    'imap.username',
                    _vm.$t('settings.imap.username')
                  )},on:{"input":function($event){return _vm.resetInputError('imap.username')}},model:{value:(_vm.form.imap.username),callback:function ($$v) {_vm.$set(_vm.form.imap, "username", $$v)},expression:"form.imap.username"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"label":_vm.$t('settings.imap.password'),"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"rules":_vm.defaultValidation(
                    'imap.password',
                    _vm.$t('settings.imap.password')
                  )},on:{"input":function($event){return _vm.resetInputError('imap.password')},"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.form.imap.password),callback:function ($$v) {_vm.$set(_vm.form.imap, "password", $$v)},expression:"form.imap.password"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.imap_on),expression:"form.imap_on"}],attrs:{"label":_vm.$t('settings.imap.inbox'),"type":"text","rules":_vm.defaultValidation('imap.inbox', _vm.$t('settings.imap.inbox'))},on:{"input":function($event){return _vm.resetInputError('imap.inbox')}},model:{value:(_vm.form.imap.inbox),callback:function ($$v) {_vm.$set(_vm.form.imap, "inbox", $$v)},expression:"form.imap.inbox"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","disabled":_vm.sending || _vm.savedItem != null},on:{"click":_vm.closeForm}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('v-btn',{attrs:{"type":"submit","disabled":_vm.sending || _vm.savedItem != null,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1),(_vm.sending)?_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e(),(_vm.errorMessage)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.$t(_vm.errorMessage)))]):_vm._e(),(_vm.savedItem)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("projects.saved", _vm.savedItem)))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }