<template>
  <v-dialog v-model="showFormDialog" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("projects.edit members") }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-select
              v-model="form.members"
              :items="meta.users"
              :item-text="memberText"
              item-value="id"
              multiple
              :label="$t('projects.fields.members')"
            >
            </v-select>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("projects.members saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/projects/",
      showPass: false,
      project_id: 0,
      reloadBeforeEdit: true,
      meta: {},
    };
  },

  methods: {
    editMembers(project, index) {
      this.apiQuery = "/projects/" + project.id + "/members/";
      this.editedIndex = index;
      this.get(this.apiQuery)
        .then((item) => {
          this.editedItem = item;
          this.form = Object.assign({}, this.defaultItem);
          this.form.members = item.members;
          this.meta.users = item.users;
          this.onDataLoaded();
          this.showFormDialog = true;
        })
        .catch((error) => {
          this.$emit("serverError", error);
        });
    },
    saveItem() {
      this.errorMessage = null;
      this.serverErrors = null;

      this.patch(this.apiQuery, this.form)
        .then((res) => {
          this.savedItem = res;
          this.$emit("itemSaved", res, this.editedIndex);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
    memberText: (item) =>
      item.first_name || item.last_name
        ? item.first_name + (item.last_name ? " " + item.last_name : "")
        : item.email,
  },
};
</script>
